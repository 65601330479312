const initializeSortableTables = () => {
  document.querySelectorAll("table").forEach(table => {
    table.querySelectorAll("th").forEach((headerCell, index) => {
      headerCell.addEventListener("click", () => {
        const tableElement = headerCell.parentElement.parentElement.parentElement;
        const isAscending = headerCell.classList.contains("th-sort-asc");
        const direction = isAscending ? "desc" : "asc";
        sortTableByColumn(tableElement, index, direction);
      });
    });
  });
};

const sortTableByColumn = (table, column, direction) => {
  const tbody = table.querySelector("tbody");
  const rows = Array.from(tbody.querySelectorAll("tr"));
  const compare = (a, b) => {
    const cellA = a.querySelector(`td:nth-child(${column + 1})`).textContent.trim();
    const cellB = b.querySelector(`td:nth-child(${column + 1})`).textContent.trim();
    const order = direction === "asc" ? 1 : -1;
    return cellA.localeCompare(cellB) * order;
  };

  rows.sort(compare).forEach(row => tbody.appendChild(row));

  table.querySelectorAll("th").forEach(th => th.classList.remove("th-sort-asc", "th-sort-desc"));
  table.querySelector(`th:nth-child(${column + 1})`).classList.toggle("th-sort-asc", direction === "asc");
  table.querySelector(`th:nth-child(${column + 1})`).classList.toggle("th-sort-desc", direction === "desc");
};

export { initializeSortableTables };
